import {Image, SIZES} from '@fupa/fupa-uikit';
import React from 'react';
import styled from 'styled-components';

const ChipImageWrapper = styled.div`
  width: ${SIZES['24']};
  height: ${SIZES['24']};
  margin-left: 4px;
  margin-right: -6px;
`;

const ChipImage = props => {
  return (
    <ChipImageWrapper className={props.className}>
      <Image width={24} height={24} {...props} />
    </ChipImageWrapper>
  );
};

export default ChipImage;
