import React from 'react';
import {Subheading} from 'app/styles/heading';
import {COLORS, SIZES, Image, Line} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';
import {object, string} from 'prop-types';
import {ProfileRowDataContainer, ProfileRowWrapper, ProfileTypography} from 'app/components/profile/ProfileStyles';
import {StyledTypography} from 'app/styles/content';
import {Person} from '@mui/icons-material';

const DataContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${SIZES['8']} ${SIZES['16']};
`;

const ProfileLinkRow = ({
  isDeactivated,
  profileSlug,
  profileImage,
  profileName,
  additionalInformation,
  optionalInformation,
  subheading,
  type,
  noLinking,
}) => {
  let showSubheading;
  if (subheading) {
    showSubheading = <Subheading variant='subheading2'>{subheading}</Subheading>;
  }

  let showOptionalInformation;
  if (optionalInformation) {
    showOptionalInformation = (
      <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
        {optionalInformation}
      </ProfileTypography>
    );
  }
  const checkImage = profileImage ? (
    <Image entity={type} circle={true} src={profileImage} ratio='1:1' width={64} height={64} />
  ) : (
    <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
  );

  const content = (
    <DataContainer>
      {checkImage}
      <ProfileRowDataContainer>
        <StyledTypography isDeactivated={isDeactivated} variant='body1' lineHeight={20}>
          {profileName}
        </StyledTypography>
        <StyledTypography isDeactivated={isDeactivated} variant='body2' lineHeight={20}>
          {additionalInformation}
        </StyledTypography>
        {showOptionalInformation}
      </ProfileRowDataContainer>
    </DataContainer>
  );

  const componentData =
    !profileSlug || noLinking || isDeactivated ? (
      content
    ) : (
      <LinkOnComponent enablehover={true.toString()} to={`/${type}/${profileSlug}`}>
        {content}
      </LinkOnComponent>
    );
  return (
    <ProfileRowWrapper>
      {showSubheading}
      {componentData}
      <Line />
    </ProfileRowWrapper>
  );
};

ProfileLinkRow.propTypes = {
  profileSlug: string.isRequired,
  profileImage: object.isRequired,
  profileName: string.isRequired,
  additionalInformation: string,
  type: string.isRequired,
};

export default ProfileLinkRow;
