import {SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';

const Subheading = styled(Typography)`
  margin-top: 0;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  padding-left: ${props => (!props.center ? '1rem' : 'none')};
  text-align: ${props => (props.center ? 'center' : 'none')};
  line-height: ${SIZES['24']};
`;

const CardHeading = styled.div`
  display: flex;
  align-items: center;
`;

const CardSubheading = styled(Subheading)`
  &&& {
    padding-left: 0;
  }
`;

const CaptionContainer = styled.div`
  padding: ${SIZES['8']} 0 ${SIZES['8']} ${SIZES['16']};
`;

const CompetitionCaption = styled(Typography)`
  padding-left: 1rem;
  height: ${SIZES['20']};
  &&& {
    font-size: 0.875rem;
  }
`;

const CompetitionCaptionDouble = styled(Typography)`
  display: flex;
  flex: 0 100;
  padding: 1rem 0.5rem 0;
  justify-content: space-between;
  &&& {
    font-size: 0.875rem;
  }
  span {
    height: ${SIZES['20']};
  }
`;

export {Subheading, CardHeading, CardSubheading, CaptionContainer, CompetitionCaption, CompetitionCaptionDouble};
