import React from 'react';
import {LinkOnComponent} from 'app/components/links/link';
import {
  catchDataError,
  checkApiRedirect,
  checkCompetitionPathRedirect,
  parseLinkHeader,
  RedirectException,
} from 'app/helpers/apiHelpers';

const unique = arr => {
  const u = {},
    a = [];
  for (let i = 0, l = arr.length; i < l; ++i) {
    if (!u.hasOwnProperty(arr[i])) {
      a.push(arr[i]);
      u[arr[i]] = 1;
    }
  }
  return a;
};

const groupMatches = (group, competitionId) => {
  return group.matches.filter(match => match.competition.id === competitionId);
};

const groupByCompetition = (matchesGroupedByKickoff, clubSlug) => {
  //  input matchesGroupedByKickoff: [{day: string, matches: array}, {day: string, matches: array}]
  let transformedItems = [];
  matchesGroupedByKickoff.map(group => {
    const result = {day: group.day, competitionGroup: []};
    const competitionsByDay = group.matches.map(match => match.competition).filter(elem => elem);
    if (competitionsByDay.length) {
      // Find unique competitions, not working for objects
      const compId = competitionsByDay.map(competition => competition.id);
      const uniqueId = compId.filter((ele, pos) => compId.indexOf(ele) === pos);

      uniqueId.forEach(competitionId => {
        // specific logic for 'testspiele' to split into agegroups
        if (competitionId === 42) {
          // check for ageGroup to split several objects
          const matches = groupMatches(group, competitionId);

          const ageGroups = matches.map(match => {
            // check for home or away team to get all ageGroup slugs
            const team = match.homeTeam.clubSlug === clubSlug ? match.homeTeam : match.awayTeam;
            return team.ageGroup.slug;
          });
          const uniqueAgeGroup = ageGroups.filter((ele, pos) => ageGroups.indexOf(ele) === pos);
          if (uniqueAgeGroup.length) {
            const matchesByAgeGroup = uniqueAgeGroup.map(ageGroup =>
              matches.filter(match => {
                // check for home or away team to get all competitionGroups
                const team = match.homeTeam.clubSlug === clubSlug ? match.homeTeam : match.awayTeam;
                return team.ageGroup.slug === ageGroup;
              })
            );

            matchesByAgeGroup.map(matches =>
              result.competitionGroup.push({
                day: group.day,
                competition: competitionsByDay.find(comp => comp.id === competitionId),
                matches,
              })
            );
          }
        } else {
          result.competitionGroup.push({
            day: group.day,
            competition: competitionsByDay.find(comp => comp.id === competitionId),
            matches: groupMatches(group, competitionId),
          });
        }
      });
    } else {
      result.competitionGroup.push({...group, competition: null});
    }
    transformedItems.push(result);
  });
  // output transformedItems: [{day: string, competitionGroups: [{competition: object, matches: array}]}]
  return transformedItems;
};

const groupMatchesByKickoff = (matches, pointer, seasonSlug) => {
  const kickoff = matches
    .map(match => match.kickoff?.substring(0, 10))
    .filter(elem => elem)
    .sort();
  if (pointer === 'prev' || (seasonSlug && seasonSlug !== 'current')) {
    kickoff.reverse();
  }
  const result = [];
  if (kickoff.length) {
    const uniqueKickoff = unique(kickoff);
    uniqueKickoff.forEach(day => {
      result.push({
        day,
        matches: matches.filter(match => match.kickoff.substring(0, 10) === day),
      });
    });
  } else {
    result.push({day: null, matches});
  }
  return result;
};

const convertDateToParam = (date = new Date()) => {
  return date.toISOString().substr(0, 10);
};

const getTodayAndYesterdayAsQuery = () => {
  const DAY_IN_MS = 86400000;
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate.valueOf() - DAY_IN_MS);
  const yesterday = convertDateToParam(yesterdayDate);
  const today = convertDateToParam(todayDate);
  return {today, yesterday};
};

// Not only for Actions but all of them are needed for MatchPageActions
const getCompetitionLinkPath = competition => {
  return competition?.category?.id === 1 ? 'league' : 'cup';
};

const createCompetitionPathFromParams = (competitionType, leagueSlug, seasonSlug) => {
  let urlPath = `/${competitionType}/${leagueSlug}`;
  if (seasonSlug) {
    urlPath += `/season/${seasonSlug}`;
  }
  return urlPath;
};

const getSeasonSlugFromCompetitionSeason = competitionSeason => {
  if (competitionSeason.active) {
    return null;
  }
  if (competitionSeason.season?.slug) {
    return competitionSeason.season.slug;
  }
  if (competitionSeason.seasonSlug) {
    return competitionSeason.seasonSlug;
  }
  return null;
};

const generateBasicCompetitionLink = (competitionSeason, competitionType) => {
  if (!competitionSeason?.slug) {
    return null;
  }
  // set first path with string prop or generate based on competition category (not available in all api responses)
  const category = competitionSeason?.category?.id ? getCompetitionLinkPath(competitionSeason) : null;
  const type = competitionType ?? category;
  const defaultType = 'league';
  const validEventTypes = [defaultType, 'cup'];
  const competition = validEventTypes.includes(type) ? type : defaultType;

  const seasonSlug = getSeasonSlugFromCompetitionSeason(competitionSeason);
  return createCompetitionPathFromParams(competition, competitionSeason.slug, seasonSlug);
};

const generateMatchDayTitle = round => {
  let matchDayTitle = '';
  if (round) {
    if (round.name) {
      matchDayTitle = round.name;
    } else {
      if (round.type === 'league' && round.number !== 99 && round.number !== 98) {
        matchDayTitle = `${round.number}. Spieltag`;
      } else if (round.type === 'cupgroup') {
        matchDayTitle = `Gruppe ${round.title}`;
      } else if (round.type === 'cupko') {
        matchDayTitle = round.title;
      }
    }
  }

  return matchDayTitle;
};

const generateMatchCompetitionInfo = (matchInfo, stringOnly) => {
  let competitionInfo = matchInfo.competition ? matchInfo.competition.middleName : '';
  const link = generateBasicCompetitionLink(matchInfo.competition);
  if (matchInfo.round) {
    const matchDayTitle = generateMatchDayTitle(matchInfo.round);
    if (matchDayTitle) {
      competitionInfo += `, ${matchDayTitle}`;
    }
  }
  return link && !stringOnly ? <LinkOnComponent to={link}>{competitionInfo}</LinkOnComponent> : competitionInfo;
};

export {
  groupMatchesByKickoff,
  groupByCompetition,
  convertDateToParam,
  getTodayAndYesterdayAsQuery,
  parseLinkHeader,
  checkApiRedirect,
  checkCompetitionPathRedirect,
  catchDataError,
  RedirectException,
  getCompetitionLinkPath,
  generateBasicCompetitionLink,
  generateMatchCompetitionInfo,
  generateMatchDayTitle,
  createCompetitionPathFromParams,
};
