import React from 'react';
import styled from 'styled-components';
import {COLORS, FONTS} from '@fupa/fupa-uikit';
import {decodeHTML} from 'app/helpers/encodeHTMLInStrings';

// MainText needs to be a div, otherwise dangerouslySetInnerHTML would not hydrate correctly
const MainText = styled.div`
  font-family: ${FONTS.PTSans};
  font-size: ${FONTS['16']};
  line-height: 1.7;
  white-space: pre-line;

  ${props => props.theme.desktopLayout`
    font-size: ${FONTS['18']};
  `};

  a {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
    &:focus,
    &:hover,
    &:visited,
    &:active {
      color: ${COLORS.fupaSecondary};
    }
  }
  h2 {
    font-family: ${FONTS.Oswald};
    font-size: ${FONTS['20']};
    font-weight: ${FONTS.regular};
  }
  h3 {
    font-family: ${FONTS.Oswald};
    font-size: ${FONTS['18']};
    font-weight: ${FONTS.regular};
  }

  em {
    font-style: normal;
    font-weight: ${FONTS.bold};
  }

  iframe {
    max-width: 100%;
    margin: 1rem 0 !important; // Note: Don't wanna do that, but need to overwrite inline style set by instagram script
  }
`;

const InnerHtmlText = ({text, className}) => {
  return <MainText className={className} dangerouslySetInnerHTML={{__html: decodeHTML(text)}} />;
};

export {InnerHtmlText};
