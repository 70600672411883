import React from 'react';
import styled from 'styled-components';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import {string, oneOfType, number, bool, oneOf} from 'prop-types';
import {LinkOnComponent} from 'app/components/links/link';
import {FlexContainer, StyledTypography} from 'app/styles/content';
import {InnerHtmlText} from 'app/components/text/InnerHtmlText';
import {Chip} from '@mui/material';
import ChipImage from 'app/components/chip/ChipImage';

const DataContainer = styled.div`
  padding: 0 ${SIZES['16']};
`;

const IconAndTextWithLabelContainer = styled(FlexContainer)`
  flex-direction: row;
  svg {
    margin-right: ${SIZES['16']};
    margin-top: ${SIZES['4']};
  }
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  text-align: ${props => props.alingment};
  padding-bottom: ${props => (props.nopadding ? 0 : SIZES['16'])};
  line-height: ${SIZES['20']};
`;

const Item = styled.div`
  display: flex;
  justify-content: ${props => (props.alingment === 'right' ? 'flex-end' : 'flex-start')};
`;

const Text = styled(StyledTypography)`
  text-decoration: ${props => (props.decoration ? 'underline' : 'none')};
`;

const TextWithLabelJustifyContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  flex: 1;
`;

const TextWithLabelContainer = ({children}) => {
  return <DataContainer>{children}</DataContainer>;
};

const TextWithLabel = ({label, data, noPaddingBottom, link, decoration, alignment, className, asInnerHTML, img}) => {
  const textComponent = asInnerHTML ? (
    <InnerHtmlText className={className} text={data} />
  ) : (
    <Text variant='body1' useOverflow decoration={decoration}>
      {data}
    </Text>
  );

  if (link) {
    const image = <ChipImage entity={img.type} src={img.src} circle={true} ratio={'1:1'} fit={true} />;
    return (
      <Data className={className} alingment={alignment} nopadding={noPaddingBottom}>
        <Typography variant='caption1'>{label}</Typography>
        <Item alingment={alignment}>
          <Chip
            style={{display: 'flex'}}
            avatar={image}
            label={data}
            variant='outlined'
            clickable
            component={LinkOnComponent}
            to={link}
          />
        </Item>
      </Data>
    );
  }

  return (
    <Data className={className} alingment={alignment} nopadding={noPaddingBottom}>
      <Typography variant='caption1'>{label}</Typography>
      {textComponent}
    </Data>
  );
};

TextWithLabel.propTypes = {
  label: string.isRequired,
  data: oneOfType([string, number]).isRequired,
  noPaddingBottom: bool,
  link: string,
  decoration: bool,
  alignment: oneOf(['left', 'right', 'center']),
  rel: string,
};

TextWithLabel.defaultProps = {
  noPaddingBottom: false,
  link: '',
  decoration: false,
  alignment: 'left',
};

export {TextWithLabel, TextWithLabelContainer, TextWithLabelJustifyContainer, IconAndTextWithLabelContainer};
