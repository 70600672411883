import styled from 'styled-components';
import {COLORS, SIZES, ELEVATIONS, Typography} from '@fupa/fupa-uikit';

const ProfileWrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-column-gap: 8px; /* legacy - Safari < 10.1, Safari iOS < 10.3 */
  column-gap: 8px;
  grid-row-gap: 16px; /* legacy - Safari < 10.1, Safari iOS < 10.3 */
  row-gap: 16px;
  justify-content: space-evenly;
  @media only screen and (max-width: 359px) {
    padding: 1rem 0;
  }
`;

// Profile Card

const ProfileCard = styled.div`
  display: flex;
  padding: 0 ${SIZES['16']} ${SIZES['16']};
  background-color: ${COLORS.white};
  box-shadow: ${ELEVATIONS.Card};
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
`;

const ProfilePhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem;
`;

const ProfilePhoto = styled.div`
  height: ${SIZES['64']};
  position: relative;
`;

const ClickableProfilePhoto = styled(ProfilePhoto)`
  cursor: pointer;
  &&& {
    height: auto;
  }
`;

const ProfileInfoColumnContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  ${props => (props.justify ? `align-self: ${props.justify}` : null)};
  width: 100%;
  max-width: 25rem;
  overflow: hidden;
`;

const ProfileInfoColumn = styled.div`
  flex: 50%;
  padding-top: 1rem;
  position: relative;
  overflow: hidden;
`;

const ProfileStats = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: ${SIZES['16']};
`;

const ProfileStatsData = styled.div`
  &:first-child {
    flex: 1 60%;
    justify-content: flex-start;
  }

  display: flex;
  justify-content: flex-end;
  flex: 1 20%;
  align-items: center;
  svg {
    margin-right: ${SIZES['4']};
  }
`;

// Profile Row

const ProfileRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 100%;
`;

const ProfileRowDataContainer = styled(ProfileRowWrapper)`
  &&& {
    flex: 1 80%;
  }

  justify-content: flex-start;
  padding-left: ${SIZES['16']};
`;

// Profile Statistics Row

const StatisticsCaption = styled(Typography)`
  flex: 1 10%;
  text-align: center;
`;

const ProfileStatisticsRowStat = styled.div`
  padding-top: ${SIZES['4']};
  flex: 1 10%;
  text-align: ${props => (props.align ? props.align : 'start')};
  ${props =>
    props.isDeactivated &&
    `
    color: ${COLORS.darkGrey};
  `}
`;

const StatisticsContainer = styled.div`
  background-color: ${COLORS.white};

  ${StatisticsCaption}:first-child, ${ProfileStatisticsRowStat}:first-child {
    text-align: start;
    ${props => props.theme.desktopLayout`
      max-width: 3rem;
    `}
  }
`;

const StatisticsCaptionLong = styled(Typography)`
  text-align: left;
  flex: 1 50%;
`;

const ProfileStatisticsRowContent = styled.div`
  display: flex;
  flex: 0 100%;
  flex-direction: column;
  ${props =>
    props.isDeactivated &&
    `
    color: ${COLORS.darkGrey};
  `}
`;

const ProfileTypography = styled(Typography)`
  &&& {
    ${props =>
      props.isDeactivated &&
      `
    color: ${COLORS.darkGrey};
  `}
  }
`;

const ProfileStatisticsRowInfo = styled.div`
  align-items: center;
  padding-top: ${SIZES['4']};
  display: inline-flex;
  flex: 1 50%;
  white-space: nowrap;
  overflow: hidden;
`;

const ProfileStatisticsRowPhoto = styled.div`
  width: ${SIZES['32']};
  height: ${SIZES['32']};
  margin-right: ${SIZES['12']};
`;

const ProfileStatisticsRowName = styled.div`
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export {
  ProfileWrapper,
  ProfileCard,
  ProfilePhotoWrapper,
  ProfilePhoto,
  ClickableProfilePhoto,
  ProfileInfoColumnContainer,
  ProfileInfoColumn,
  ProfileStats,
  ProfileStatsData,
  ProfileRowWrapper,
  ProfileRowDataContainer,
  StatisticsCaption,
  StatisticsContainer,
  StatisticsCaptionLong,
  ProfileStatisticsRowContent,
  ProfileTypography,
  ProfileStatisticsRowInfo,
  ProfileStatisticsRowStat,
  ProfileStatisticsRowPhoto,
  ProfileStatisticsRowName,
};
